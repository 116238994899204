.LongContent {
  h1 {
    font-size: 2.125rem;
    margin-bottom: 0.75rem;
    color: black;
  }

  h2 {
    font-size: 1.75rem;
    &:not(:first-child) {
      margin-top: 2rem;
      color: black;
    }
  }

  h3 {
    font-size: 1.25rem;
    &:not(:first-child) {
      margin-top: 1.5rem;
      color: black;
    }
  }

  h5 {
    &:not(:first-child) {
      margin-top: 1rem;
      color: black;
    }
  }

  h6 {
    color: #000000cc
  }

  .card{
    background: #bdbdbd26
  }

  p {
    color: black;
  }

  .table{

  th {
    color: black
  }

  td{
    border-top-color: rgb(0, 0, 0);
    color: black
  }
  
   }

  ul li {
    color: black;
  }
  
  .title{
    color: black;
  }

  ul li, ol li {
  color: #000000bd; }

}






   